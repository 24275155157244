<template>
    <div>
        <v-container>
            <v-card class="border">
                <v-card-title class="d-flex justify-center">{{ wodName }}</v-card-title>
                <v-card-text>
                    <div class="d-flex justify-center">
                        <img
                            @click="clickImage"
                            v-if='imageToShow !== "" '
                            :src="$c.API_URL + '/' + imageToShow"
                            alt=""
                        />
                        <h1 v-else>
                            No se encontro la imagen de la puntuación
                        </h1>
                    </div>            
                </v-card-text>
            </v-card>
            <v-dialog v-model="fullImage">
                <img
                    class="full-image"
                    :src="$c.API_URL + '/' + imageToShow"
                    width="1280" height="720"
                >
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imageToShow: "",
            wodName: "",
            fullImage: false
        }
    },
    async mounted() {
        const urlHash = window.location.hash;
        const url = urlHash.split("?")[1];
        const searchParams = new URLSearchParams(url);
        const puntuacion_id = searchParams.get("puntuacion_id");
        
        const response = await this.$store.dispatch("getImage", {
            puntuacion_id,
            url: true
        });
        
        const responseData = response.data[0];
        const imagePath = responseData.path_img;
        const wodName = responseData.nombre;

        if (wodName === undefined || wodName === null || wodName === "") {
            this.wodName = "No se encontro el nombre del wod";
            this.imageToShow = "";
            return;
        }
        if (imagePath === undefined || imagePath === null || imagePath === "") {
            this.imageToShow = "";
            return;
        }
        this.imageToShow = imagePath.replaceAll("./", "");
        this.wodName = wodName;
    },
    methods: {
        clickImage() {
            this.fullImage = true;
        }
    },
};
</script>

<style scoped>
img {
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    border-radius: 4px;
}

.full-image {
    width: 1280px;
    height: 720px;
}

@media only screen and (min-width: 1280px) {
    img {
        width: 1280px;
    }
}
</style>