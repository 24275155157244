<template>
    <div>
        <Imagenes />
    </div>
</template>

<script>
import Imagenes from '../components/Imagenes/Imagenes.vue';

export default {
    components: {
        Imagenes,
    }
};
</script>